<template>
  <div id="app">
    <!-- <Menu active-name="1-2" :open-names="['1']" style="height:100%;" :theme="theme">
      <Submenu name="1">
        <template slot="title">
          <Icon type="ios-analytics" />
          山东菏泽曹县
        </template>
        <MenuGroup title="Item 1">
          <MenuItem name="1-1" to="/index">Option 1</MenuItem>
          <MenuItem name="1-2">Option 2</MenuItem>
        </MenuGroup>
        <MenuGroup title="Item 2">
          <MenuItem name="1-3">Option 3</MenuItem>
          <MenuItem name="1-4">Option 4</MenuItem>
        </MenuGroup>
      </Submenu>
      <Submenu name="2">
        <template slot="title">
          <Icon type="ios-filing" />
          Navigation Two
        </template>
        <MenuItem name="2-1">Option 5</MenuItem>
        <MenuItem name="2-2">Option 6</MenuItem>
        <Submenu name="3">
          <template slot="title">Submenu</template>
          <MenuItem name="3-1">Option 7</MenuItem>
          <MenuItem name="3-2">Option 8</MenuItem>
        </Submenu>
      </Submenu>
      <Submenu name="4">
        <template slot="title">
          <Icon type="ios-cog" />
          Navigation Three
        </template>
        <MenuItem name="4-1">Option 9</MenuItem>
        <MenuItem name="4-2">Option 10</MenuItem>
        <MenuItem name="4-3">Option 11</MenuItem>
        <MenuItem name="4-4">Option 12</MenuItem>
      </Submenu>
    </Menu> -->
    <div class="router">
      <router-view></router-view>
    </div>
    <!-- 查看公告 end -->
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        dialogVisible: false,
        theme: "dark"
      }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
  }
</script>

<style>
  *{
    margin:0;
    padding:0;
  }
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    
  }

  #app {
    display: flex;
    flex-direction: row;
  }

  .router {
    width:100%;
    /* padding:20px; */
    background-color:#fff;
    height:100%;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;

  }

  a {
    text-decoration: none;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  p {
    margin: 0%;
  }

  .dialogview>>>.el-dialog__body {
    height: 842px;
    padding: 0px;
  }

  .dialogview .el-dialog__header,
  .dialogview .el-dialog__body {
    padding: 0%;
    overflow: hidden;
  }

  .dialogview .header {
    height: 40px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 40px;
    border-bottom: 2px solid #e71f17;
    box-sizing: content-box;
  }

  .dialogview .header .title {
    float: left;
    font-family: "华文行楷";
    color: #ae0221;
    display: flex;
  }

  .dialogview .header p {
    float: right;
  }

  .dialogview .header .title img {
    width: 40px;
    margin-right: 5px;
  }

  .dialogview .content {
    padding: 10px 20px;
    margin-bottom: 50px;
    height: calc(100% - 312px);
  }

  .dialogview .content h2 {
    color: #e71f17;
    text-align: center;
    font-weight: bold;
  }

  .dialogview .content .details p {
    font-size: 16px;
  }

  .dialogview .content .details p img {
    width: 100%;
    height: auto;
  }

  .dialogview .footer {
    width: 200px;
    height: 100px;
    position: relative;
    right: 40px;
    float: right;
    padding-bottom: 30px;
    box-sizing: content-box;
  }

  .dialogview .footer img {
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .dialogview .footer .desc {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 16px;
  }

  .dialogview>>>p img {
    width: 100%;
    height: auto;
  }

  .centered>>>.el-date-editor {
    width: 100%;
  }

  .rows {
    padding: 20px 0;
  }

  .el-row .box-card .el-card__body {
    padding: 20px 0;
  }

  .el-row .box-card .content {
    padding-bottom: 15px;
    width: 100%;
    height: 85px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    border-bottom: 1px solid #f1f1f1;
    line-height: 30px;
  }

  .el-row>>>p {
    height: 100%;
  }

  .el-row>>>p img {
    height: 100%;
  }

  .clear-footer {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>