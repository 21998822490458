import Vue from 'vue'
import App from './App.vue'
import router from './router';
import dataV from '@jiaminghi/data-view'
import BaiduMap from 'vue-baidu-map'
import animated from 'animate.css'
import http from './api/index';
import fontSize from './utils/font.js';
import './utils/screen.js'

// axios.defaults.baseURL = '/api'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Oxd2EwHejruOq9agHmoiaQ6E4DWNiG6i'
})

Vue.use(dataV)
Vue.use(animated)

Vue.prototype.$http = http;
Vue.prototype.$fontSize = fontSize;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
