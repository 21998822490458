import axios from 'axios'
// 头部验证/请求拦截
axios.defaults.baseURL = '/api'

// 响应拦截
axios.interceptors.response.use(config => {
  return config
})

export function get(url, paramss = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: paramss,
    }).then((response) => {
      resolve(response.data)
    })
      .catch((error) => {
        reject(error)
      })
  })
}
export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        // 关闭进度条
        if (response != undefined) {
          resolve(response.data)
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export function posts(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, { ...data }).then(
      (response) => {
        // 关闭进度条
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
// upload

export function postupload(url, file) {
  return new Promise((resolve, reject) => {
    let fd = new FormData()
    fd.append('file', file)
    axios.post(url, fd).then(
      (response) => {
        // 关闭进度条
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export function uploadfiles(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    }).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
