import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)
Vue.use(VueCookies)
// 解决路由跳转时报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
};
export const routes = [
  { path: '/index', meta: { title: '地图' }, component: () => import('@/view/index/index') },
  { path: '/message', meta: { title: '发送消息' }, component: () => import('@/view/message/message') },
]


/**
 *  系统设置
 * 统一样式的组件在菜单栏显示
 *
 *  1. path 和 name 的名称要一致。
 *  2. 父节点path 需要加 / , 子节点不需要加/
 *  4. 路由名称使用驼峰命名  首字母小写，第二个单词大写。
 *  5. path 和 name ，尽量简写【值得是意思简写，而不是单词简写】，但是英文一定要写全
 *  6.一级菜单基本在views下创建文件夹, 2级菜单在一级菜单的文件夹下创建文件
 *
 * */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [...routes]
})
// 前置导航守卫判断是否有节点权限
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next('/index')
  }
  document.title = "洪塘街道党群服务红色矩阵"
  let nodeInfos = JSON.parse(window.sessionStorage.getItem('nodeInfos'))
  if (nodeInfos) {
    let nodePermission = { add: true, del: true, edit: true }
    for (let i = 0, len = nodeInfos.length; i < len; i++) {
      let nodePath = "/" + nodeInfos[i].node_controller + "/" + nodeInfos[i].node_action;
      if (to.path == nodePath) {
        switch (nodeInfos[i].node_name) {
          case "添加": nodePermission.add = true; break;
          case "编辑": nodePermission.edit = true; break;
          case "删除": nodePermission.del = true; break;
          default:
        }
      }
    }
    window.sessionStorage.setItem('nodePermission', JSON.stringify(nodePermission))
  }
  next()
})
export default router
